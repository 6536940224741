@import '../variables.scss';

#hint_on_mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}

#hint_on_mobile em {
  color: $teal;
}