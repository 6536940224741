@import '../variables.scss';

.margin_row {
  display: flex;
  justify-content: space-between;
}

.indented {
  padding-left: 3rem;
}

.centered_text {
  text-align: center;
}

.new_paragraph {
  margin-top: 1rem;
}

#container {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: $container-padding;
  overflow: auto;
}

#content {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

#options_content {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
