// Gruvbox dark
$background: #282828;
$lighter-background: #35312f;
$color: #ebdbb2;
$green: #83b871;
$teal: #779b8d;
$yellow: #d18f1e;
$gray: #928374;

// Threshold for assuming we're on a mobile device
$mobile-screen-width: 800px;

// Padding around both columns
$container-padding: 2rem;
