@import url('https://fonts.googleapis.com/css2?family=Fira+Mono:wght@400;700&display=swap');
@import 'variables.scss';

* {
  font-family: 'Fira Mono', source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
  background: $background;
  color: $color;
  line-height: 1.25rem;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $yellow;
}
